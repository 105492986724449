import { HttpClient, HttpParams, HttpParamsOptions } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  OperationUserListItemDto,
  OperationUserRoleListItemDto,
  OperationRoleCreateDto,
  OperationRoleDto,
  OperationRoleListRequestDto,
  OperationRoleListResultDto,
  OperationRoleUpdateDto
} from '@ifhms/models/admin';
import { Observable } from 'rxjs';

@Injectable()
export class OperationRoleService {
  constructor(
    @Inject('apiUrl') private apiUrl: string,
    private http: HttpClient
  ) {
  }

  getList(feedlotId: string): Observable<OperationUserRoleListItemDto[]> {
    return this.http.get<OperationUserRoleListItemDto[]>(`${this.apiUrl}/Feedlot/${feedlotId}/Roles/SelectList`);
  }

  getTable(feedlotId: string, request: OperationRoleListRequestDto): Observable<OperationRoleListResultDto> {
    const httpParams: HttpParamsOptions = {
      fromObject: {
        ...request
      }
    };

    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<OperationRoleListResultDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Roles/Table`, options);
  }

  get(feedlotId: string, id: string): Observable<OperationRoleDto> {
    return this.http.get<OperationRoleDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Roles/${id}`);
  }

  getRoleUsers(feedlotId: string, roleId: string): Observable<OperationUserListItemDto[]> {
    return this.http.get<OperationUserListItemDto[]>(`${this.apiUrl}/Feedlot/${feedlotId}/Roles/Users/${roleId}`);
  }

  create(feedlotId: string, roleCreate: OperationRoleCreateDto): Observable<OperationRoleDto> {
    return this.http.post<OperationRoleDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Roles`, roleCreate);
  }

  update(feedlotId: string, roleUpdate: OperationRoleUpdateDto): Observable<OperationRoleDto> {
    return this.http.put<OperationRoleDto>(`${this.apiUrl}/Feedlot/${feedlotId}/Roles`, roleUpdate);
  }

  isNameUnique(feedlotId: string, name: string): Observable<boolean> {
    const httpParams: HttpParamsOptions = {
      fromObject: { name }
    };
    const options = {
      params: new HttpParams(httpParams)
    };

    return this.http.get<boolean>(`${this.apiUrl}/Feedlot/${feedlotId}/Roles/IsNameUnique`, options);
  }
}
