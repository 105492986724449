export enum OperationPermissionsSettings {
  IsAdmin = 'isAdmin',

  // New Format
  SettingsAdgSettingsView = 'feedlotSettings.canViewAdgSettings',
  SettingsAdgSettingsEdit = 'feedlotSettings.canEditAdgSettings',
  SettingsZeroScaleReminderView = 'feedlotSettings.canViewZeroScaleReminderSettings',
  SettingsZeroScaleReminderEdit = 'feedlotSettings.canEditZeroScaleReminderSettings',
  SettingsTargetWeightView = 'feedlotSettings.canViewTargetWeightSettings',
  SettingsTargetWeightEdit = 'feedlotSettings.canEditTargetWeightSettings',

  // Old Format
  SettingsGeneralRead = 'feedlotSettings.general.read',
  SettingsGeneralWrite = 'feedlotSettings.general.write',
  SettingsLocationRead = 'feedlotSettings.location.read',
  SettingsLocationWrite = 'feedlotSettings.location.write',
  SettingsPricingSettingsRead = 'feedlotSettings.pricingSettings.read',
  SettingsPricingSettingsWrite = 'feedlotSettings.pricingSettings.write',
  SettingsDefaultValuesRead = 'feedlotSettings.defaultValues.read',
  SettingsDefaultValuesWrite = 'feedlotSettings.defaultValues.write',
  SettingsArrivalRead = 'feedlotSettings.arrival.read',
  SettingsArrivalWrite = 'feedlotSettings.arrival.write',
  SettingsReHandlingRead = 'feedlotSettings.reHandling.read',
  SettingsReHandlingWrite = 'feedlotSettings.reHandling.write',
  SettingsPostMortemRead = 'feedlotSettings.postMortem.read',
  SettingsPostMortemWrite = 'feedlotSettings.postMortem.write',
  SettingsTreatmentProtocolRead = 'feedlotSettings.treatmentProtocol.read',
  SettingsTreatmentProtocolWrite = 'feedlotSettings.treatmentProtocol.write',
  SettingsLabourChargesRead = 'feedlotSettings.labourCharges.read',
  SettingsLabourChargesWrite = 'feedlotSettings.labourCharges.write',
  SettingsProcedureChargesRead = 'feedlotSettings.procedureCharges.read',
  SettingsProcedureChargesWrite = 'feedlotSettings.procedureCharges.write',
  SettingsPenChargesRead = 'feedlotSettings.penCharges.read',
  SettingsPenChargesWrite = 'feedlotSettings.penCharges.write',
  SettingsOtherFeesRead = 'feedlotSettings.otherFees.read',
  SettingsOtherFeesWrite = 'feedlotSettings.otherFees.write',
  SettingsCustomFieldsManagementRead = 'feedlotSettings.customFieldsManagement.read',
  SettingsCustomFieldsManagementWrite = 'feedlotSettings.customFieldsManagement.write',
  SettingsTagRead = 'feedlotSettings.tag.read',
  SettingsTagWrite = 'feedlotSettings.tag.write',
  SettingsSortGroupRead = 'feedlotSettings.sortGroup.read',
  SettingsSortGroupWrite = 'feedlotSettings.sortGroup.write',
  SettingsSortGroupTemplatesRead = 'feedlotSettings.sortGroupTemplates.read',
  SettingsSortGroupTemplatesWrite = 'feedlotSettings.sortGroupTemplates.write',
  SettingsCCIARead = 'feedlotSettings.ccia.read',
  SettingsCCIAWrite = 'feedlotSettings.ccia.write',
  SettingsPmIntegrationRead = 'feedlotSettings.pmIntegration.read',
  SettingsPmIntegrationWrite = 'feedlotSettings.pmIntegration.write',
  SettingsDSortIntegrationRead = 'feedlotSettings.dSortIntegration.read',
  SettingsDSortIntegrationWrite = 'feedlotSettings.dSortIntegration.write'
}
