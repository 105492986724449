import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ProviderScope, TRANSLOCO_SCOPE } from '@jsverse/transloco';

import { AbstractFormFieldConfigComponent } from '@sersi/angular/formly/core';
import { getTranslationScope } from '@common/angular/translation';

import { PermissionFieldConfigService } from '../../services';

@Component({
  selector: 'aa-admin-procurement-permission-form-group',
  templateUrl: './procurement-permission-form-group.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ PermissionFieldConfigService ]
})
export class ProcurementPermissionFormGroupComponent extends AbstractFormFieldConfigComponent {

  private fieldService = inject(PermissionFieldConfigService);
  private providerScope = inject<ProviderScope>(TRANSLOCO_SCOPE);

  protected override scopedNamespace = `${getTranslationScope(this.providerScope)}.components.procurement-permission`;

  constructor() {
    super();
    this.fieldService.scopedNamespace += '.procurement-permission';
  }

  protected getFieldGroupConfig(): FormlyFieldConfig[] {
    return [
      this.setSettingsSection()
    ];
  }

  private setSettingsSection(): FormlyFieldConfig {
    return {
      fieldGroupClassName: 'dual-col mb-5',
      fieldGroup: [
        this.fieldService.getReadPermissionFieldConfig('canViewSettings', ['canEditSettings']),
        this.fieldService.getWritePermissionFieldConfig('canEditSettings', ['canViewSettings'])
      ]
    }
  }

}
